import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button';
import { BoxArrowLeft } from './ReactBootstrapIcons';
import 'react-toastify/dist/ReactToastify.min.css';
import { useToasts } from 'react-toast-notifications';
import { fetchJsonFromApiBlobResponse } from '../common/FetchFunctions';
import edge_logo from '../../images/edge_logo.jpg';
import chrome_logo from '../../images/chrome_logo.jpg';
import firefox_logo from '../../images/firefox_logo.jpg';

export default function ExtensionDownloadModal(props) {
	const { addToast } = useToasts();

	// // console.log("Rendering NavBar", location)
	// useEffect(() => {
	// }, []);

	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			style={{
				fontFamily: 'Montserrat-SemiBold',
				fontSize: props.globalState.fontSize,
				color: '#000000',
			}}
		>
			<Modal.Header closeButton>
				<Modal.Title
					id="contained-modal-title-vcenter"
					style={{
						fontFamily: 'Montserrat-SemiBold',
						fontSize: props.globalState.fontSize * 1.2,
						color: '#20358c',
					}}
				>
					Download Browser Extensions
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div
					style={{
						height: '2rem',
						fontFamily: 'Montserrat-Regular',
						fontSize: '0.8rem',
						color: '#20358c',
						paddingTop: '0.5rem',
					}}
				>
					Click
					<a
						onClick={() => {
							addToast(`Your download will begin shortly`, {
								appearance: 'success',
								autoDismiss: true,
								autoDismissTimeout: 2000,
							});
							fetchJsonFromApiBlobResponse(`downloadExtensionInstallationGuide`)
								.then(blob => {
									const url = window.URL.createObjectURL(new Blob([blob]));
									const link = document.createElement('a');
									link.href = url;
									link.setAttribute('download', `WIOCC HUB - Browser Extensions Installation Guide.pdf`);

									// Append to html link element page
									document.body.appendChild(link);

									// Start download
									link.click();

									// Clean up and remove the link
									link.parentNode.removeChild(link);
									props.onHide();
								})
								.catch(error => {
									console.log(error.message);
								});
						}}
						href="#e"
						style={{
							fontFamily: 'Montserrat-SemiBold',
							color: 'blue',
							cursor: 'pointer',
						}}
					>
						&nbsp;Here&nbsp;
					</a>
					to Download Installation Instructions.
				</div>

				<div
					style={{
						height: '2rem',
						backgroundColor: '#e9ecef',
						fontFamily: 'Montserrat-SemiBold',
						fontSize: '0.8rem',
						color: '#20358c',
						paddingTop: '0.5rem',
						marginTop: '1rem',
						textAlign: 'center',
					}}
				>
					Select Your Browser To Download Extension.
				</div>
				<div
					className="container-fluid"
					style={{
						width: '99%',
						overflowY: 'auto',
						maxHeight: '50vh',
						display: 'flex',
						justifyContent: 'center',
						marginTop: '1rem',
					}}
				>
					<div
						style={{
							width: '20%',
						}}
					>
						<a
							onClick={() => {
								window.open('https://chromewebstore.google.com/detail/wiocc-hubs-netsuite-exten/bhkidbmfhpdlcalgagelofmjeflcjhhd', '_blank');
								props.onHide();
							}}
							href="# "
							style={{
								fontFamily: 'Montserrat-SemiBold',
								cursor: 'pointer',
							}}
						>
							<img src={chrome_logo} alt="Chrome Extension" height="40" width="40" />
							<figcaption>Chrome</figcaption>
						</a>
					</div>
					<div
						style={{
							width: '20%',
						}}
					>
						<a
							href="# "
							onClick={() => {
								window.open('https://chrome.google.com/webstore/detail/ogaodcmcgjjejlkkgokfinjbhjjffnmc', '_blank');
								props.onHide();
							}}
							style={{
								fontFamily: 'Montserrat-SemiBold',
								cursor: 'pointer',
							}}
						>
							<img src={edge_logo} alt="Edge Extension" height="40" width="40" />
							<figcaption>Edge</figcaption>
						</a>
					</div>

					<div style={{}}>
						<a
							href="# "
							onClick={() => {
								addToast(`Your download will begin shortly`, {
									appearance: 'success',
									autoDismiss: true,
									autoDismissTimeout: 2000,
								});
								fetchJsonFromApiBlobResponse(`downloadFirefoxExtension`)
									.then(blob => {
										const url = window.URL.createObjectURL(new Blob([blob]));
										const link = document.createElement('a');
										link.href = url;
										link.setAttribute('download', `wiocc_hubs_netsuite_extension-1.0.5-fx.xpi`);

										// Append to html link element page
										document.body.appendChild(link);

										// Start download
										link.click();

										// Clean up and remove the link
										link.parentNode.removeChild(link);
										props.onHide();
									})
									.catch(error => {
										console.log(error.message);
									});
							}}
							style={{
								fontFamily: 'Montserrat-SemiBold',
								cursor: 'pointer',
							}}
						>
							<img src={firefox_logo} alt="Firefox Extension" height="40" width="40" />
							<figcaption>Firefox</figcaption>
						</a>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button
					size="small"
					color="warning"
					variant="contained"
					style={{ margin: 'auto' }}
					onClick={() => {
						props.onHide();
					}}
					disabled={false}
					startIcon={<BoxArrowLeft />}
				>
					&nbsp;&nbsp;Back
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
